import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import {
    RateCardsValues,
} from './RateCards.types'
import getBaseLeaseConfig from './BaseLease/BaseLease.config'
import getEmbargoCountriesConfig from './EmbargoCountries/EmbargoContries.config'
import getOtherLaneFeesConfig from './OtherLaneFees/OtherLaneFees.config'
import getAdditionalFeesConfig from './AdditionalFees/AdditionalFees.config'

const config: SkyNetTableConfig<keyof RateCardsValues> = {
    name: 'RateCards',
    tabs: [
        getBaseLeaseConfig(),
        getOtherLaneFeesConfig(),
        getAdditionalFeesConfig(),
        getEmbargoCountriesConfig(),
    ],
}

export default config
