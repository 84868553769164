import {
    LoggerConfiguration,
    LoggerType,
    SensorCode,
} from 'app/types/enums'

import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'

export enum LoggerStatus {
    ACTIVE = 'ACTIVE',
    DECOMMISSIONED = 'DECOMMISSIONED',
    OUT_OF_SERVICE = 'OUT_OF_SERVICE',
    READY_TO_SHIP = 'READY_TO_SHIP',
    SERVICE = 'SERVICE',
}

type ConfigLoggerOption = {
    value: string,
    label: string
}

export type ConfigLogger = {
    title: string,
    name: string,
    loggerType?: LoggerType,
    sensorCodes?: SensorCode[],
    positions?: string[],
    options: ConfigLoggerOption[]
}

export enum LoggerPosition {
    AMBIENT = 'AMBIENT',
    INTERNAL = 'INTERNAL',
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    BACK = 'BACK',
    DOOR = 'DOOR',
    UNSPECIFIED = 'UNSPECIFIED',
    CF_AMBIENT = 'CF_AMBIENT',
}

export enum LoggerDataTypes {
    TEMPERATURE = 'TEMPERATURE',
    LOCATION_LATITUDE = 'LOCATION_LATITUDE',
    LOCATION_LONGITUDE = 'LOCATION_LONGITUDE',
    DOOR = 'DOOR',
    MESSAGE_ID = 'MESSAGE_ID',
    TEMPERATURE_MEASUREMENT_TIMESTAMP = 'TEMPERATURE_MEASUREMENT_TIMESTAMP',
    BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
    BATTERY_VOLTAGE_DROP = 'BATTERY_VOLTAGE_DROP',
    MARKER = 'MARKER',
    GATEWAY_NUMBER = 'GATEWAY_NUMBER',
    RSSI = 'RSSI',
    SNR = 'SNR',
    IS_INVALID = 'IS_INVALID',
    IS_RESET_DEVICE = 'IS_RESET_DEVICE',
    IS_HISTORICAL = 'IS_HISTORICAL',
    RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY',
    BATTERY_LEVEL = 'BATTERY_LEVEL',
    IS_HISTORIC_READOUT = 'IS_HISTORIC_READOUT',
}

type LoggerTypeDetails = {
    changedOn?: string,
    createdOn?: string,
    dataTypes?: string[],
    deleted?: boolean
    hasMultipleSensors: boolean,
    loggerType?: LoggerType
    positionConfiguration: LoggerConfiguration[],
    priority?: number,
}

type LoggerBase = {
    containerSerialNumber?: string,
    id?: number,
    lastEventDoorOnUtc?: number,
    lastMeasuredOnUtc?: number,
    loggerNumber?: string,
    loggerQrCode?: string,
    loggerStatus?: LoggerStatus,
    loggerType?: LoggerType,
    loggerMode?: LoggerMode,
}

export enum LoggerMode {
    OFFLINE = 'OFFLINE',
    SHELF_MODE = 'SHELF_MODE',
    ACTIVE = 'ACTIVE',
}

export type LoggerTable = LoggerBase & {
    gatewayGatewayId?: number,
    gatewayImeiMac?: string,
    locationName?: string,
    batteryVoltage?: string,
    rentingCompany?: Company,
    loggerAge?: string,
    loggerMode?: LoggerMode,
}

export type LoggerResponse = LoggerBase & {
    allowedSensorCodes?: SensorCode[],
    calibrationDocuments?: any[],
    changedOn?: string,
    containerId?: number,
    createdBy?: string,
    createdOn?: string,
    deleted?: boolean,
    loggerTransmissionRateSeconds?: number,
    loggerTypeDetails?: LoggerTypeDetails
    reservedForUsers?: any[],
    batteryVoltage?: string,
    gateway?: {
        id: number,
        locationRemark: string,
        location: {
            id: string,
        },
    },
    location?: {id: number}
    rentingCompany?: Company,
}

export enum LoggerTabs {
    DOCUMENTS = 'documents'
}

export default LoggerType
