import {
    RequestConfig,
} from 'app/types/request.types'

type AdditionalFeesRequests = {
    filterRequest: () => RequestConfig
}

const requests: AdditionalFeesRequests = {
    filterRequest: () => {
        return {
            url: 'additionalfeeratecard/filter-request/all',
        }
    },
}

export default requests
