import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    OtherFeesAllowed,
} from 'app/shared-components/Fees'
import {
    FeeChargeability, ProductType,
} from 'app/types/enums'
import {
    RateCardsTabs,
} from '../RateCards.types'
import {
    OtherLaneFeesTable,
} from './OtherLaneFees.types'

import requests from './OtherLaneFees.request'

export default function getOtherLaneFeesConfig(): SkyNetTableTab<keyof OtherLaneFeesTable> {
    return {
        name: 'Other Lane Fees',
        url: RateCardsTabs.OtherLaneFees,
        customUrl: requests.filterRequest().url,
        fields: [
            {
                name: 'feeName',
                labelKey: 'LaneFeesRateCard.feeName',
                componentName: 'EnumValue',
                allowedValuesEnum: OtherFeesAllowed,
            },
            {
                name: 'feeType',
                labelKey: 'LaneFeesRateCard.feeType',
            },
            {
                name: 'feeChargability',
                labelKey: 'LaneFeesRateCard.feeChargability',
                componentName: 'EnumValue',
                allowedValuesEnum: FeeChargeability,
                minWidth: '25%',
            },
            {
                name: 'productType',
                labelKey: 'LaneFeesRateCard.productType',
                componentName: 'EnumValue',
                allowedValuesEnum: ProductType,
            },
            {
                name: 'description',
                labelKey: 'LaneFeesRateCard.description',
                minWidth: '35%',
            },
            {
                name: 'fixedUsdFeeValue',
                labelKey: 'LaneFeesRateCard.fixedUsdFeeValue',
            },
            {
                name: 'fixedEurFeeValue',
                labelKey: 'LaneFeesRateCard.fixedEurFeeValue',
            },
            {
                name: 'fixedChfFeeValue',
                labelKey: 'LaneFeesRateCard.fixedChfFeeValue',
            },
        ],
    }
}
