export type Damage = {
    id: number,
    damageNumber: string,
    serialNumber: string,
    containerModel: string,
    reportedOn: string,
    damageType: string,
    damageStatus: string,
    reportingServiceCenter: { id: number },
    causingOrder: string,
    repairingServiceCenter: { id: number },
    repairedOn: string,
    repairedBy: string,
    damageComment: string,
    repairComment: string,
    reportedBy: string,
    reportingDepartment: string,
    damageAttachments: {id: number},
    repairAttachments: {id: number},
    container: {id: number},
    createdOn: string,
    inspectionProcessAttachment: {id: number}
    repairProcessAttachment: {id: number}
}

export enum DamageTrackingDomains {
    Damages = 'Damages'
}
