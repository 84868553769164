import {
    RequestConfig,
} from 'app/types/request.types'

type BaseLeaseRequests = {
    filterRequest: () => RequestConfig
}

const requests: BaseLeaseRequests = {
    filterRequest: () => {
        return {
            url: 'embargocountryratecard/filter-request/all',
        }
    },
}

export default requests
