import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    SimulationProfile,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export default (): SkyNetTableConfig => {
    return {
        name: 'SavedSimulationsTable',
        tabs: [{
            name: 'TransportSimulation',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'simulationNumber',
                    labelKey: 'TransportSimulationView.simulationNumber',
                },
                {
                    name: 'routeOriginAirportIataCode',
                    filterField: 'routeOriginAirportIataCode',
                    labelKey: 'TransportSimulationView.routeOriginAirportIataCode',
                },
                {
                    name: 'routeDestinationAirportIataCode',
                    filterField: 'routeDestinationAirportIataCode',
                    labelKey: 'TransportSimulationView.routeDestinationAirportIataCode',
                },
                {
                    name: 'createdBy',
                    filterField: 'createdBy',
                    labelKey: 'TransportSimulationView.createdBy',
                },
                {
                    name: 'createdOn',
                    labelKey: 'Damages.createdOn',
                    componentName: 'DateTimeCell',
                },
                {
                    name: 'simulationProfile',
                    componentName: 'EnumValue',
                    allowedValuesEnum: SimulationProfile,
                    filterField: 'simulationProfile',
                    labelKey: 'TransportSimulationView.simulationProfile',
                },
                {
                    name: 'simulationFinalized',
                    componentName: 'Boolean',
                    labelKey: 'TransportSimulationView.simulationFinalized',
                },
            ],
        }],
    }
}
