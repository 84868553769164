import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderStatuses,
    ProductType,
} from 'app/types/enums'
import {
    InternalTransportTableType,
    OrderSubType,
    SeaFreightCarrier,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

import request from './internalTransport.request'
import {
    Pooling,
} from '../../Pooling/pooling.types'
import {
    pickupAddInfoFields,
} from '../../Pooling/configs/pickupAddInfo.config'
import {
    pickupAddPickupFields,
} from '../../Pooling/configs/pickupAddPickUp.config'
import {
    pickupDeliveryConfirmationFields,
} from '../../Pooling/configs/pickupDeliveryConfirmation.config'
import {
    transportDepartureFields,
} from '../../Pooling/configs/transportDeparture.config'
import {
    transportArrivalFields,
} from '../../Pooling/configs/transportArrival.config'
import {
    deliveryAddInfoFields,
} from '../../Pooling/configs/deliveryAddInfo.config'
import {
    deliveryAddPickupFields,
} from '../../Pooling/configs/deliveryAddPickup.config'
import {
    deliveryConfirmationFields,
} from '../../Pooling/configs/deliveryConfirmation.config'

export default (isOverdueFilter: boolean): SkyNetTableConfig<keyof (
    InternalTransportTableType & Pooling)> => {
    return {
        name: 'OrderManagement.IT_Orders',
        tabs: [
            {
                name: 'All Orders',
                url: CommonRoutes.ALL,
                requestUrl: request.all.url,
                defaultTab: true,
                fields: [
                    {
                        name: 'orderNumber',
                        reduced: true,
                    },
                    {
                        name: 'from',
                        filterField: 'from',
                        filterType: FilterType.Array,
                        reduced: true,
                    },
                    {
                        name: 'to',
                        filterField: 'to',
                        filterType: FilterType.Array,
                        reduced: true,
                    },
                    {
                        name: 'destinationCountry',
                        filterField: 'destinationCountry',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'destinationLocationLocationName',
                        filterField: 'destinationLocationLocationName',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'originLocationLocationName',
                        filterField: 'originLocationLocationName',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'plannedPickup',
                        filterField: 'plannedPickup',
                        filterType: FilterType.DateRange,
                        labelKey: 'PostponementOrderDto.requestedPickup',
                        componentName: 'DateTimeCell',
                    },
                    {
                        name: 'requestedDelivery',
                        labelKey: 'Order.requestedDelivery',
                        filterField: 'requestedDelivery',
                        filterType: FilterType.DateRange,
                        componentName: 'DateTimeCell',
                    },
                    {
                        name: 'forwarderExport',
                        labelKey: 'Order.forwarderExport',
                        filterField: 'forwarderExport',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'forwarderImport',
                        labelKey: 'Order.forwarderImport',
                        filterField: 'forwarderImport',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'orderStatus',
                        filterField: 'orderStatus',
                        filterType: FilterType.Array,
                        componentName: 'EnumValue',
                        allowedValuesEnum: OrderStatuses,
                        labelKey: 'OrderView.orderStatus',
                    },
                    {
                        name: 'orderSubType',
                        componentName: 'EnumValue',
                        allowedValuesEnum: OrderSubType,
                        reduced: true,
                        filterField: 'orderSubType',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'masterNumber',
                        labelKey: 'Order.masterNumber',
                        sorting: false,
                    },
                    {
                        name: 'houseNumber',
                        labelKey: 'Order.houseNumber',
                        sorting: false,
                    },
                    {
                        name: 'carrier',
                        componentName: 'EnumValue',
                        allowedValuesEnum: SeaFreightCarrier,
                        labelKey: 'Order.carrier',
                        sorting: false,
                    },
                    {
                        name: 'mainMeansOfTransport',
                        componentName: 'TransportMode',
                        labelKey: 'OrderPoolingInternalTransportsView.mode',
                        width: '150px',
                        filterField: 'mainMeansOfTransport',
                        filterType: FilterType.Array,
                    },
                    {
                        name: 'requiredContainers',
                        componentName: 'RequiredContainersList',
                        allowedValuesEnum: ProductType,
                        sorting: false,
                        labelKey: 'Order.requiredContainers',
                    },
                ],
            },
            {
                name: 'Pickup: Add Info',
                url: 'pickup-add-info',
                requestUrl: 'pooling-pickup-add-info',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: pickupAddInfoFields,
            },
            {
                name: 'Pickup: Add Pickup',
                url: 'pickup-add-pickup',
                requestUrl: 'pooling-pickup-add-pickup',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: pickupAddPickupFields,
            },
            {
                name: 'Pickup: Delivery Confirmation',
                url: 'pickup-delivery-confirmation',
                requestUrl: 'pooling-pickup-delivery-confirmation',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: pickupDeliveryConfirmationFields,
            },
            {
                name: 'Transport: Departure',
                url: 'transport-departure',
                requestUrl: 'pooling-transport-departure',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: transportDepartureFields,
            },
            {
                name: 'Transport: Arrival',
                url: 'transport-arrival',
                requestUrl: 'pooling-transport-arrival',
                fields: transportArrivalFields,
            },
            {
                name: 'Delivery: Add Info',
                url: 'delivery-add-info',
                requestUrl: 'pooling-delivery-add-info',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: deliveryAddInfoFields,
            },
            {
                name: 'Delivery: Add Pick-up',
                url: 'delivery-add-pickup',
                requestUrl: 'pooling-delivery-add-pickup',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: deliveryAddPickupFields,
            },
            {
                name: 'Delivery: Confirmation',
                url: 'delivery-confirmation',
                requestUrl: 'pooling-delivery-confirmation',
                filters: {
                    isOverdue: isOverdueFilter,
                },
                fields: deliveryConfirmationFields,
            },
        ],
    }
}
