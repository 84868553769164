import {
    RejectionReason,
    OrderTemperatureRange,
    ProductType,
    LeaseType,
    Currency,
    LocationType,
    StepTypeDetailed,
    FreightTypes,
    OrderStatuses,
    PaymentTerms,
    InvoiceDesignation,
    SubstepName,
} from 'app/types/enums'
import FxRate from 'app/shared-components/FxRate/FxRate.types'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'

import {
    CustomerOrderSource,
} from './CustomerTransport/CustomerTransport.types'

export enum CancellationReasons {
    CANCELLATION_CONTAINER_UNAVAILABILITY = 'CANCELLATION_CONTAINER_UNAVAILABILITY',
    CUSTOMER_REQUEST_POSTPONEMENT_REBOOKED = 'CUSTOMER_REQUEST_POSTPONEMENT_REBOOKED',
    CANCELLATION_CUSTOMER_REQUEST = 'CANCELLATION_CUSTOMER_REQUEST',
    CANCELLATION_OTHER = 'CANCELLATION_OTHER',
    CANCELLATION_TRUCK_UNAVAILABILITY = 'CANCELLATION_TRUCK_UNAVAILABILITY'
}

export enum DeliveryServiceType {
    DELIVERY_ROAD = 'DELIVERY_ROAD',
    PICKUP = 'PICKUP',
}

export enum CollectionServiceType {
    COLLECTION = 'COLLECTION',
    DROPOFF_FORWARDER = 'DROPOFF_FORWARDER',
    DROPOFF_SKYCELL = 'DROPOFF_SKYCELL',
    DROPOFF_AIRPORT = 'DROPOFF_AIRPORT',
}

export enum OrderTypes {
    CUSTOMER_TRANSPORT = 'CUSTOMER_TRANSPORT',
    INTERNAL_TRANSPORT = 'INTERNAL_TRANSPORT',
}

export enum OrderSubType {
    LONG_TERM_RENTAL = 'LONG_TERM_RENTAL',
    DIRECT_LEASE = 'DIRECT_LEASE',
    AIRLINE_LEASE = 'AIRLINE_LEASE',
    EVENT = 'EVENT',
    GENERAL_FREIGHT = 'GENERAL_FREIGHT',
    STOCK_TRANSFER = 'STOCK_TRANSFER',
    REVERSE_LOGISTICS = 'REVERSE_LOGISTICS',
}

export enum TransportModeRoad {
    FTL = 'FTL',
    LTL = 'LTL',
}
export enum StepStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED',
    CLOSED_INCOMPLETE_INFORMATION = 'CLOSED_INCOMPLETE_INFORMATION',
}

export enum OrderStatusTransitionTo {
    NOT_STARTED = 'NOT_STARTED',
    SELECTED = 'SELECTED',
    BOOKED = 'BOOKED',
    TRANSPORT = 'TRANSPORT',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
    CONFIRMED = 'CONFIRMED',
    RESERVED = 'RESERVED',
    TO_BE_PROVISIONED = 'TO_BE_PROVISIONED',
    READY_TO_SHIP = 'READY_TO_SHIP',
    POSITIONING = 'POSITIONING'
}

export enum StepType {
    ROAD = 'ROAD',
    AIR = 'AIR',
    SEA = 'SEA',
    CONFIRMATION = 'CONFIRMATION',
    EVENT = 'EVENT',
    CUSTOMS = 'CUSTOMS',
    RAIL = 'RAIL'
}

export enum InvoiceStatus {
    CANCELLED = 'CANCELLED',
    CHECKEXTRADAYS = 'CHECKEXTRADAYS',
    CREATED = 'CREATED',
    OVERDUE = 'OVERDUE',
    PAID = 'PAID',
    SENTTOABACUS = 'SENTTOABACUS',
    SENTTOBILLINGCOMPANY = 'SENTTOBILLINGCOMPANY'
}

export enum TransportModeSea {
    FCL = 'FCL',
    LCL = 'LCL'
}

export enum SeafreightContainerSize {
    TWENTY_FEET = 'TWENTY_FEET',
    FORTY_FEET = 'FORTY_FEET',
}

export enum DefaultTruckingComment {
    TRUCKING_COMMENT_TAIL_LIFT = 'TRUCKING_COMMENT_TAIL_LIFT',
    TRUCKING_COMMENT_PICKUP_ASAP = 'TRUCKING_COMMENT_PICKUP_ASAP',
    TRUCKING_COMMENT_DELIVER_ASAP = 'TRUCKING_COMMENT_DELIVER_ASAP',
    TRUCKING_COMMENT_STRAP_BELTS = 'TRUCKING_COMMENT_STRAP_BELTS',
    TRUCKING_COMMENT_DAMAGED_CONTAINERS = 'TRUCKING_COMMENT_DAMAGED_CONTAINERS',
    TRUCKING_COMMENT_ISF_COPY = 'TRUCKING_COMMENT_ISF_COPY',
}

export enum SeaFreightCarrier {
    COSCO = 'COSCO',
    EVERGREEN = 'EVERGREEN',
    OOCL = 'OOCL',
    MAERSK = 'MAERSK',
    MSC = 'MSC',
    HAPAG_LLOYD = 'HAPAG_LLOYD',
    CMA_CGM = 'CMA_CGM',
    HYUNDAI = 'HYUNDAI',
    YANG_MING = 'YANG_MING',
    ZIM = 'ZIM',
    MOL = 'MOL',
    HAMBURG_SUUD = 'HAMBURG_SUUD',
    APL = 'APL',
    SM_LINES = 'SM_LINES',
    ONE = 'ONE'
}

export enum PositionType {
    LEASE = 'LEASE',
    FEE = 'FEE',
    CANCELLATION = 'CANCELLATION',
    DAMAGE = 'DAMAGE',
    LOSS = 'LOSS'
}

export enum InvoiceNormalAddon {
    ADDON = 'ADDON',
    NORMAL = 'NORMAL',
}

export enum InvoiceBulkOrIndividual {
    BULK = 'BULK',
    INDIVIDUAL = 'INDIVIDUAL'
}

export enum PostponementReason {
    CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
    INTERNAL_REQUEST = 'INTERNAL_REQUEST',
    CUSTOMER_REQUEST_NO_COST = 'CUSTOMER_REQUEST_NO_COST',
}

export enum ResponsibleDepartment {
    MARKETING = 'MARKETING',
    OPS = 'OPS',
    PRODUCTION = 'PRODUCTION',
    R_D = 'R_D',
    TECHNICAL_SERVICES = 'TECHNICAL_SERVICES',
}

export enum ModificationType {
    INTERNAL = 'INTERNAL',
    CUSTOMER = 'CUSTOMER',
}

export enum DangerousGoods {
    CLASS_1_1 = 'CLASS_1_1',
    CLASS_1_2 = 'Allowed in SkyCell Containers',
    CLASS_1_3 = 'Allowed in SkyCell Containers',
    CLASS_1_4 = 'Allowed in SkyCell Containers',
    CLASS_1_5 = 'Allowed in SkyCell Containers',
    CLASS_1_6 = 'Allowed in SkyCell Containers',
    CLASS_2_1 = 'Allowed in SkyCell Containers',
    CLASS_2_2 = 'Allowed in SkyCell Containers',
    CLASS_2_3 = 'On request - needs pre-approval by SkyCell',
    CLASS_3 = 'Allowed in SkyCell Containers',
    CLASS_4_1 = 'Allowed in SkyCell Containers',
    CLASS_4_2 = 'On request - needs pre-approval by SkyCell',
    CLASS_4_3 = 'Allowed in SkyCell Containers',
    CLASS_5_1 = 'Allowed in SkyCell Containers',
    CLASS_5_2 = 'Allowed in SkyCell Containers',
    CLASS_6 = 'On request - needs pre-approval by SkyCell',
    CLASS_7 = 'On request - needs pre-approval by SkyCell',
    CLASS_8 = 'On request - needs pre-approval by SkyCell',
    CLASS_9 = 'Allowed in SkyCell Containers',
}

export type StepProgress = {
    id?: number,
    substepCompleted: boolean,
    substepName: SubstepName,
}

export type Location = {
    id: number,
    locationName: string,
    locationType: LocationType,
    unloCode?: string,
    iataCode?: string,
}

export type OrderStep = {
    id: number,
    stepStatus: StepStatus,
    stepTypeDetailed: StepTypeDetailed,
    stepProgress: StepProgress[],
    transportModeRoad: TransportModeRoad,
    transportModeForwarder: TransportModeRoad,
    stepType: StepType,
    pickupLocation: Location,
    location: Location,
    originAirport: Location,
    originSeaport: Location,
    deliveryLocation: Location,
    destinationAirport: Location,
    destinationSeaport: Location,
    forwarder: {
      id: number,
    },
    forwarderId: number,
    pickupLocationId: number,
    locationId: number,
    airlineId: number,
    airline: {id: number},
    deliveryLocationId: number,
    originSeaportId: number,
    originAirportId: number,
    destinationAirportId: number,
    destinationSeaportId: number,
    forwarderContactsIds: number[],
    forwarderContacts: {id:number}[],
    mawbDocumentCopy: {id: number}[],
    hawbDocumentCopy: {id: number}[],
    locationContactsIds: number[],
    deliveryLocationContactsIds: number[],
    deliveryLocationContacts: {id: number}[],
    pickupLocationContactsIds: number[],
    pickupLocationContacts: {id: number}[],
    hbolDocumentCopy: {id: number}[],
    cmrDocumentCopy: {id: number}[],
    mbolDocumentCopy: {id: number[]},
    contactsIds: number[],
    contacts: {id: number}[],
    locationContacts: {id: number}[],
    eventBooker: {id: number},
    informationEntered: string,
    transportModeSea: TransportModeSea,
    seafreightContainerSize: SeafreightContainerSize,
    numberOfTrucks: number,
    numberOfVans: number,
    isTemperatureControlled: boolean,
    truckingComment: string,
    forwarderAdHoc: string,
    defaultTruckingComment: DefaultTruckingComment,
    requestedExecution: string,
    estimatedExecution: string,
    actualExecution: string,
    requestedPickup: string,
    estimatedPickup: string,
    actualPickup: string,
    pickupComment: string,
    requestedDelivery: string,
    estimatedDelivery: string,
    actualDelivery: string,
    deliveryComment: string,
    truckingCompany: string,
    driverName: string,
    driverContactNumber: string,
    licensePlateNumber: string,
    cmrNumber: string,
    cmrReceived: boolean,
    mbolReceived: boolean,
    mbolNumber: string,
    hbolReceived: boolean,
    hbolNumber: string,
    seaFreightCarrier: SeaFreightCarrier,
    actualShipDeparture: string,
    actualShipArrival: string,
    seaFreightComment: string,
    mawbReceived: boolean,
    mawbNumber: string,
    hawbReceived: boolean,
    hawbNumber: string,
    flightNumber: string,
    estimatedFlightDeparture: string,
    actualFlightDeparture: string,
    estimatedFlightArrival: string,
    actualFlightArrival: string,
    flightComment: string,
    comment: string,
    eventName: string,
    eventContactPerson: string,
    informationReceived: string,
    eventLocation: string,
    deliveryLocationEvent: string,
    pickupLocationEvent: string,
}

export type OrderStepFields = keyof OrderStep

export type OrderStatusTransition = {
    to: OrderStatusTransitionTo,
    completedOn: string,
    completedBy: string,
}

export type RequiredContainersInternal = {
    id?: number,
    quantity: number,
    containerType: {
        id?: number,
        containerModel?: string
    },
}

export type SelectedContainer = {
    id: number,
    jypId: string,
    tempRange: string,
    serialNumber: string,
    containerStatusEnriched: string,
    containerTypeContainerModel: string,
    containerTypeContainerCode: string,
    collectionDropoffPoint: string,
    returnedOn: string,
    isRejected: boolean,
    rejectionComment: string,
    rejectionReason: RejectionReason,
}

export type SelectedContainerFields = keyof SelectedContainer

export type GeneralFreight = {
    id: number | string,
    material: string,
    description: string,
    quantity: number,
    unit: string,
    weightKg: number,
    dimensions: string,
}

export type GeneralFreightFields = keyof GeneralFreight

export type PreviousOrder= {
    id: number,
}

export type Position = {
    positionCode: string,
    positionType: PositionType,
    positionDescription: string,
    discountPercentage?: string,
    unitPrice: number,
    quantity: number,
    id: number,
}

export type PositionPrePopulationType = {
    createdOn: string,
    changedOn: string,
    createdBy: string,
    changedBy: string,
    isAutomatedChange: boolean,
    id: number,
    archived: boolean,
    outgoingInvoiceId: number,
    positionType: PositionType,
    quantity: number,
    unitPrice: number,
    positionDescription: string,
    positionCode: string,
}

export type PositionFields = keyof Position

export type Postponement = {
    id: number,
    postponementRequestedOn: string,
    requestedHandover: string,
    previousRequestedHandover: string,
    leaseStartTimestamp: string,
    previousLeaseStartTimestamp: string,
    postponedBy: string,
    postponementReason: PostponementReason,
    isOrderConfirmed: boolean,
}

export type PostponementFields = keyof Postponement

export type OutgoingInvoice = {
    id: number,
    invoiceAmount: number,
    invoiceDesignation: InvoiceDesignation,
    invoiceNumber: string,
    abacusOrderNumber: string,
    invoiceDate: string,
    invoiceStatus: InvoiceStatus,
    positions: Position[],
    invoiceNormalAddon: InvoiceNormalAddon,
    invoiceBulkOrIndividual: InvoiceBulkOrIndividual,
    invoiceTitle: string,
    laneInfo: string,
    invoiceComment: string,
    invoiceSentToAbacusOn: string,
    createdBy: string,
    changedBy: string,
}

export type OutgoingInvoiceFields = keyof OutgoingInvoice

type Fee = {
    id: number,
    feeDescription: string,
    feeRange: number,
    feeValue: number,
    feeChargeability: string,
    feeName: string,
    productType: string,
}

export type LaneFees = {
    commentForCustomer?: string,
    laneFeesCommentForBilling?: string,
    fees: Fee[],
    truckingCapacity: [],
    minOrder: number,
    expressOrderTimeline: number,
    postponementFeeTimeline: number,
    expressDaysType: string,
    postponementDaysType: string,
    contractBasisCurrency: Currency,
    fxRate: FxRate,
    laneGroupId: number,
    laneFeesNumber: string,
    id: number,
}

export type AdditionalFees = {
    id: number,
    additionalFeesCommentForBilling?: string,
    fees: Fee[]
    cancellationFeeTimeline: number,
    cancellationDaysType: string,
    contractBasisCurrency: Currency,
    fxRate: FxRate,
    additionalFeesNumber: string,
    contractBasisId: number,
}

type Billing = {
    id: number,
    leaseStartTimestamp: string,
    leaseEndTimestamp: string,
    baseLeaseDays: number,
    leaseStartDate: string,
    baseLeaseUntilTimestamp: string,
    billingComment: string,
    hasBillingCompleted: boolean,
    billingCompany: {
        id: number,
        markUp: string,
        paymentTerms: PaymentTerms,
        hasBulkInvoice: boolean,
        hasCombinedInvoice: boolean,
    },
    billingCurrency: Currency,
    extraDays?: number,
    billedAmount: number,
    outgoingInvoices: OutgoingInvoice[],
    leaseEndDate: string,
    requiresManualBillingReviewOps: boolean,
    laneFees: {
        id: number,
        laneFeesNumber: string,
        baseLeaseDays: number,
        allBaseLeaseProductType: ProductType[],
        changedOn: string,
    },
    additionalFees: {
        id: number,
        additionalFeesNumber: string,
        changedOn: string,
    }
}

type Lane = {
    id: number,
    laneNumber: string,
    orderInstructions: string,
    laneGroup: {
        id: number,
        allowedProductTypes: ProductType,
        contractBasisId: number,
    },
    laneCommentBilling: string,
    tempRange: OrderTemperatureRange,
}

export type BillingCompany = {
    id: number,
    markUp: string,
    paymentTerms: PaymentTerms,
    hasBulkInvoice: boolean,
    hasCombinedInvoice: boolean,
    remarks: string
}

export type BillingInformationType = {
    orderNumber: string,
    orderStatus: OrderStatuses,
    cancellationDate: string,
    cancellationReason: CancellationReasons,
    cancellationComment: string,
    customer: {id: number},
    customerId: number,
    customerContacts: {id: number}[],
    customerContactsId: number[],
    orderComment: string,
    customerReference: string,
    temperatureRange: OrderTemperatureRange,
    personInCharge: string,
    laneCommentBilling: string,
    tempRange: OrderTemperatureRange,
    laneNumber: string,
    pricingAccount: Account,
    selectedContainers: SelectedContainer[],
    billing: {
        billingComment: string,
        leaseStartTimestamp: string,
        hasBillingCompleted: boolean,
        requiresManualBillingReviewPricing: boolean,
        requiresManualBillingReviewOps: boolean,
        billingCompany: BillingCompany,
        billingCurrency: Currency,
        id: number,
        leaseEndTimestamp: string,
        baseLeaseDays: number,
        baseLeaseUntilTimestamp: string,
        extraDays?: number,
        billedAmount: number,
        outgoingInvoices: OutgoingInvoice[],
        leaseStartDate: string,
        leaseEndDate: string,
        additionalFeesCommentForBilling: string,
        laneFeesCommentForBilling: string,
    },
}

export type Airline = {
    companyName: string,
    id: number,
}

export type Order = {
    id: number,
    orderNumber: string,
    createdBy: string,
    version: number,
    customerId: number,
    customer: {id: number},
    orderComment: string,
    orderEntered: string,
    tempDuringTransport: string,
    requestedHandover: string,
    requiredCargoComment: string,
    personInCharge: string,
    destinationLocationId: number,
    destinationLocation: {id: number},
    destinationAirportId: number,
    destinationAirport: {id: number},
    handoverPointId: number,
    handoverPoint: {id: number},
    consigneeId: number,
    consignee: {id: number},
    originLocationId: number,
    originLocation: {id: number},
    originAirportId: number,
    originAirport: {id: number},
    collectionDropoffPointId: number,
    collectionDropoffPoint: {id: number},
    customerContactsIds: number[],
    customerContacts: {id: number}[],
    originLocationContactsIds: number[],
    originLocationContacts: {id: number}[],
    handoverPointContactsIds: number[],
    handoverPointContacts: {id: number}[],
    collectionDropoffPointContactsIds: number[],
    collectionDropoffPointContacts: {id: number}[],
    destinationLocationContactsIds: number[],
    destinationLocationContacts: {id: number}[],
    consigneeContactsIds: number[],
    consigneeContacts: {id: number}[],
    orderReceived: string,
    customerReference: string,
    cancellationDate: string,
    cancellationComment: string,
    incoTerm: string,
    cancellationReason: CancellationReasons,
    orderStatus: OrderStatuses,
    temperatureRange: OrderTemperatureRange,
    orderSteps: OrderStep[],
    orderStatusTransitions: OrderStatusTransition[],
    pricingAccount: Account,
    leaseType: LeaseType,
    deliveryServiceType: DeliveryServiceType,
    orderSubType: OrderSubType,
    orderType: OrderTypes,
    requiredContainers: RequiredContainersCustomer[],
    requiredContainersInternal: RequiredContainersInternal[],
    selectedContainers: SelectedContainer[],
    allowedProductTypes: ProductType[],
    freightType: FreightTypes,
    transportModeRoad: TransportModeRoad,
    transportModeForwarder: TransportModeRoad,
    generalFreight: GeneralFreight[],
    previousOrders: PreviousOrder[],
    billing: Billing,
    lane: Lane,
    airline: Airline
    responsibleDepartment: ResponsibleDepartment,
    freightForwarder: {
        id: number,
    },
    leaseStartTimestamp: string,
    commentForBillingOps: string,
    dangerousGoodsClass: DangerousGoods,
    customerComment: string,
    source: CustomerOrderSource,
}

export type OrderFields = keyof Order

export type PropsTabs = {
    data?: Order,
    onSuccess: () => void
}

export type CancellationInfo = {
    cancellationReason: CancellationReasons,
    cancellationDate: string,
    cancellationComment: string,
}

export type CancelOrderData = {
    isShowCancel: boolean,
    openCancellation: boolean,
    setCancellationOpen: (value: boolean) => void,
}

export type BillingInformationResponse = {
    billingInformation: BillingInformationType,
    isDataReady: boolean,
    isFetching: boolean,
    isError: boolean,
    error: any,
}

export type OtherInfo = {
    name: string,
    value: any,
}

export type EventFlightType = {
    destination: string,
    event: string,
    flight: string,
    origin: string,
    timeOfEvent: string,
    other: OtherInfo[],
}

export enum MainMeansOfTransport {
    SEA = 'SEA',
    AIR = 'AIR',
    ROAD = 'ROAD',
    RAIL = 'RAIL'
}

export type EventFlightFields = keyof EventFlightType

export type PostponeInfo = {
    postponementReason: PostponementReason,
    requestedDelivery: string,
    requestedPickup: string,
    postponementRequestedOn: string,
    leaseStartTimestamp: string,
}

export type PostponeInfoFields = keyof PostponeInfo

export type ProductTypeContainerTempRange = {
    productType: ProductType,
    containerCodes: string[],
    temperatureRange: OrderTemperatureRange,
}

export type PreviousOrderView = {
    orderNumber: string,
    from: string,
    to: string,
    id: number,
    pricingAccountCompanyName: string,
    customerLocationName: string,
    consigneeLocationName: string,
    transportModeSea: string,
    leaseEndDate: string,
    transportModeRoad: TransportModeRoad,
    requiredContainers: string[],
    temperatureRange: OrderTemperatureRange,
    orderStatus: OrderStatuses,
}

export type PreviousOrderViewFields = keyof PreviousOrderView

export enum ModificationReason {
    CUSTOMER = 'CUSTOMER',
    INTERNAL = 'INTERNAL',
    INTERNAL_REQUEST_CONTAINER_SWAP = 'INTERNAL_REQUEST_CONTAINER_SWAP',
}

export type ModificationRequiredContainersType = {
    modificationReason: ModificationReason,
    modificationRequestedOn: string,
    modificationComment: string,
    requiredCargoComment?: string,
    modifiedBy: string,
    previousRequiredContainers: RequiredContainersCustomer[],
    requiredContainers?: RequiredContainersCustomer[],
    isOrderConfirmed?: boolean,
}

export type ModificationRequiredContainersFields = keyof ModificationRequiredContainersType

export type LaneFeeSelectorType = {
    changedOn: string,
    id: number,
    laneFeesNumber: string,
}

export type AdditionalFeeSelectorType = {
    changedOn: string,
    id: number,
    additionalFeesNumber: string,
}

export type SharedPositioningType = {
    id: number,
    orderNumber: string,
}

export type SettingField = {
    disabled: boolean,
    fieldName: string,
    required: boolean,
}

export type StepConfiguration = {
    stepProgress: SubstepName,
    stepTypeDetailed: StepTypeDetailed,
    subStepOrder: number,
    fields: SettingField[]
}

export type CustomerTransportTableType = {
    id: number,
    orderNumber: string,
    orderSubType: OrderSubType,
    orderStatus: OrderStatuses,
    from: string,
    to: string,
    requestedHandover: string,
    originLocationLocationName: string,
    destinationLocationLocationName: string,
    mawbNumber: string,
    pricingAccountCompanyName: string,
    customerLocationName: string,
    airlineCompanyName: string,
    forwarderLocationName: string,
    plannedPickup: string,
    destinationCountry: string,
    requiredContainers: RequiredContainersCustomer[],
    handoverPointLocationName: string,
    source: CustomerOrderSource,
}

export type InternalTransportTableType = {
    id: number,
    orderNumber: string,
    orderSubType: OrderSubType,
    orderStatus: OrderStatuses,
    from: string,
    to: string,
    requestedHandover: string,
    originLocationLocationName: string,
    destinationLocationLocationName: string,
    destinationCountry: string,
    plannedPickup: string,
    mainMeansOfTransport: MainMeansOfTransport,
    masterNumber: string,
    houseNumber: string,
    carrier: string,
    requiredContainers: RequiredContainersInternal[],
    requestedDelivery: string,
    forwarderImport: string,
    forwarderExport: string
}
