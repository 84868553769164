import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    PostponementFields, PostponementReason,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const columns: RichTableColumns<PostponementFields>[] = [
    {
        id: 'postponementRequestedOn',
        headerProps: {
            children: 'Requested On',
        },
        mapCellProps: 'postponementRequestedOn',
        componentName: 'DateTimeCell',
    },
    {
        id: 'postponementReason',
        headerProps: {
            children: 'Reason',
        },
        mapCellProps: 'postponementReason',
        componentName: 'EnumValue',
        allowedValuesEnum: PostponementReason,
    },
    {
        id: 'postponedBy',
        headerProps: {
            children: 'Postponed By',
        },
        mapCellProps: 'postponedBy',
    },
    {
        id: 'previousRequestedHandover',
        headerProps: {
            children: 'Previous Handover',
        },
        mapCellProps: 'previousRequestedHandover',
    },
    {
        id: 'requestedHandover',
        headerProps: {
            children: 'New Handover',
        },
        mapCellProps: 'requestedHandover',
    },
    {
        id: 'previousLeaseStartTimestamp',
        headerProps: {
            children: 'Previous Start Of Lease',
        },
        mapCellProps: 'previousLeaseStartTimestamp',
    },
    {
        id: 'leaseStartTimestamp',
        headerProps: {
            children: 'New Start Of Lease',
        },
        mapCellProps: 'leaseStartTimestamp',
        componentName: 'DateTimeCell',
    },
    {
        id: 'isOrderConfirmed',
        headerProps: {
            children: 'Order Confirmed',
        },
        mapCellProps: 'isOrderConfirmed',
        componentName: 'ChipCheckMark',
    },
]

export default columns
