import {
    Currency, LaneFeesStatus, LaneStatus, LeaseType, RangeType, LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    WithId,
    Contact,
} from 'app/types/common.types'
import {
    Location,
} from 'app/shared-components/LocationSelector'
import {
    CollectionServiceType, DeliveryServiceType, TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'

import {
    RejectedReasons,
    ReturnedReasons,
} from 'app/shared-components/LaneFees/LaneFeesForm/reasons.types'

export type ContractBasis = {
    id: number,
    contractBasisNumber: string,
    businessDevelopers: Record<string, any>[], // not string
    changedBy: string,
    changedOn: string,
    contractWithCurrencies: string[],
    createdBy: string,
    createdOn: string,
    customerQuotation: Location,
    customerQuotationContacts: string[],
    plannedPaymentTerms: number,
    account: Account,
    supportingDocuments: Record<string, any>[], // not string
    keyAccountManager: Record<string, any>,
    comment: string,
    hasNDA: boolean,
    archived?: boolean,
    currency: Currency
}

export type LaneGroup = {
    deliveryServiceType: DeliveryServiceType,
    handoverPointLocationName: string,
    collectionServiceType: CollectionServiceType,
    collectionDropoffPointLocationName?: string,
    leaseType: LeaseType,
    originAirports: string[],
    originAirportsIataCode: string[],
    destinationAirports: string[],
    destinationAirportsIataCode: string[],
    tempRange: string,
    laneGroupNumber: string,
    id: number,
    customerQuotationLocationName: string,
    contractId?: number,
    contractBasisCurrency?: string,
    handoverPoint?: {
        id: number;
    };
    handoverPointContacts?: Contact[];
    transportModeRoad?: TransportModeRoad,
    collectionDropoffPoint?: {
        id: number;
    };
    collectionDropoffPointContacts?: Contact[];
    laneGroupId?: number,
    archived?: boolean,
    comment?: string,
    changedOn?: string,
    changedBy?: string,
    handoverPointProvisional?: string,
    collectionDropoffPointProvisional?: string,
    isFtlPositioningTemperatureControlled?: boolean,
    isLtlPositioningTemperatureControlled?: boolean,
    hasImplementedLanes?: boolean,
    contractBasisId?: number,
    laneGroupTag?: string,
    laneFeesValidTo?: string,
    additionalFeesValidTo?: string,
}

export type LaneGroupForm = keyof(LaneGroup & {
    linkToContractBasis: string,
})

export type LaneGroupHandoverData = Pick<LaneGroup,
    'id' | 'isFtlPositioningTemperatureControlled'
    | 'isLtlPositioningTemperatureControlled' |
    'transportModeRoad'>

export type Lane = {
    changedBy: string,
    consigneeLocationName: string,
    customerExecutionLocationName: string,
    destAirportIataCode: string,
    id: number,
    laneNumber: string,
    laneStatus: LaneStatus,
    originAirportIataCode: string,
}

export type LaneFee = {
    changedBy: string,
    changedOn: string,
    id: number,
    laneFeesNumber: string,
    laneFeesStatus: LaneFeesStatus,
    truckingCapacity: string[],
    rentalDays: number,
    logisticsDays: number,
    validFrom: string,
    validTo: string,
    isPerformanceQualification: boolean,
}

export type Fee = {
    feeChargeability: string,
    feeName: string,
    feeValue: number,
    id: number,
    productType: string,
    feeDescription?: string,
}

export type LaneFeesDetail = {
    billingCurrency: Currency,
    contractId: number,
    laneGroupId: number,
    laneGroupNumber: string,
    laneFeesNumber: string,
    id: number,
    validFrom: string,
    validTo: string,
    laneFeesStatus: LaneFeesStatus,
    pricingNotes?: string,
    rentalDays: number,
    logisticsDays: number,
    minOrder: number,
    rejectedReason?: RejectedReasons,
    rejectedReasonHint: string,
    rejectedReasonComment: string,

    rejectedOn?: string,

    rejectedBy?: string,
    returnReason?: ReturnedReasons,
    returnReasonComment: string,
    returnCreatedOn?: string,
    returnCreatedBy?: string,
    supportingDocuments: string,
    commentForCustomer: string,
    commentForBilling: string,
    fees: Fee[],
    truckingCapacity: TruckingCapacity[],
    fxRate?: { id?: number },
    laneFeesId?: number,
    archived?: boolean,
    requiresManualBillingReview?: boolean,
    isPerformanceQualification?: boolean,
    originId?: number
    expressOrderTimeline?: number,
    expressDaysType?: RangeType,
    postponementFeeTimeline?: number,
    postponementDaysType?: RangeType,
    reviewLaneFeesReturnReason?: string,
}

export type LaneDetails = {
    id: number,
    contractId: number,
    billingCurrency: string,
    laneGroupId: number,
    laneGroupNumber: string,
    laneNumber: string,
    laneStatus: LaneStatus,
    laneId?: number,
    archived?: boolean,
    originAirport: Location,
    destinationAirport: Location,
    expPalletsYear: number,
    consignee: Location,
    consigneeContacts: Contact[],
    customerExecution: Location,
    customerExecutionContactsQuote: Contact[],
    extraDaysReminder: number,
    billingCompany: Location,
    laneCommentBilling: string,
    laneCommentOps: string,
    consigneeProvisional: string,
    pricingAccount: WithId<number>,
    handoverPoint: WithId<number>,
    collectionDroppOffPoint: WithId<number>,
    originSc: WithId<number>,
    tempRange: LaneGroupTemperatureRange,
}

export type LaneSelector = Pick<Lane, 'id' | 'laneNumber' | 'customerExecutionLocationName'> & {
    leaseType?: LeaseType,
    handoverPointLocationName: string,
    collectionDropoffPointLocationName: string,
    originScId?: number,
    originScLocationName?: string
}

export type TruckingCapacity = {
    id: number,
    maxNumberOfContainersPerTruck: number,
    maxNumberOfContainersPerVan: number,
    productType: string,
}

export enum PricingDomains {
    PricingDashboard = 'pricing-dashboard',
    ContractBasis = 'contract-basis',
    LaneGroups = 'lane-groups',
    Lanes = 'lanes',
    AlLogisticPricing = 'al-logistic-pricing',
    AlLeasePricing = 'al-lease-pricing',
    Quotes = 'quotes'
}
