import {
    useMemo,
} from 'react'
import {
    App, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    RatesDomains,
} from '../Rates.types'

export default function useRatesRoutes() {
    const skyNetNavigate = useSkyNetNavigate()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.RATES],
            RateCards: {
                route: RatesDomains.RateCards,
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [skyNetNavigate])
}
