import React, {
    useEffect,
    useMemo,
    useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Card from 'app/shared-components/Card'
import useGetServiceCenterByLocation from 'app/Apps/ContainerManagement/ServiceCenters/hooks/useGetServiceCenterByLocation'
import {
    ContainerAvailabilityForecastContainerStatuses,
    ForecastAvailabilityOptions,
} from 'app/Apps/ContainerManagement/ServiceCenters/serviceCenters.types'
import strOrDateToDateStr from 'app/utils/date/strOrDateToDateStr'
import OptionPanel
    from 'app/Apps/ContainerManagement/ServiceCenters/Monitoring/ ContainerAvailabilityForecast/OptionPanel'
import ContainerAvailabilityForecastChart
    from 'app/Apps/ContainerManagement/ServiceCenters/Monitoring/ ContainerAvailabilityForecast/ContainerAvailabilityForecastChart'

import {
    LocationSelectionDataType, PositioningSelection,
    PositioningSetupType,
} from './orderConfirmation.types'
import {
    CancelOrderData,
    Order,
} from '../../orders.types'
import getServiceCenterMap from './getServiceCenterMap'
import PositioningSetup from './PositioningSetup/PositioningSetup'
import useUpdateOriginServiceCenter from './hooks/useUpdateOriginServiceCenter'
import useCancelOrder from '../../hooks/useCancelOrder'
import CancellationOrderDialog from '../../Components/CancellationOrderDialog'
import useOrderManagementRoutes from '../../../hooks/useOrderManagementRoutes'
import LocationSelection from './LocationSelection'
import PostponeDialog from '../CustomerTransportsOverview/PostponeDialog'
import useUpdateStatusOrder from '../hooks/useUpdateStatusOrder'
import useGetContainerAvailability from './hooks/useGetContainerAvailability'
import RequiredContainersAvailability from './RequiredContainersAvailability'
import useStyles from './OrderConfirmation.style'

const OrderConfirmation = ({
    positioningSetup,
    order,
    isDeliveryRoad,
    onSuccess,
    invalidateOrder,
}: {
    positioningSetup: PositioningSetupType,
    order: Order,
    isDeliveryRoad: boolean,
    onSuccess: () => void
    invalidateOrder: () => void
}) => {
    const {
        classes,
    } = useStyles()
    const {
        CustomerOrders: CustomerOrdersRoutes,
    } = useOrderManagementRoutes()
    const {
        openCancellation,
        setCancellationOpen,
    }: CancelOrderData = useCancelOrder({
        orderStatus: order?.orderStatus,
    })

    const serviceCenterMap = useMemo(() => {
        return getServiceCenterMap(positioningSetup)
    }, [positioningSetup])

    const [
        locationSelectionData,
        setLocationSelectionData,
    ] = useState<LocationSelectionDataType>({
        ...serviceCenterMap.origin[positioningSetup.originServiceCenterSelection
        || PositioningSelection.DEFAULT].data,
        originServiceCenterSelection: positioningSetup.originServiceCenterSelection
            || PositioningSelection.DEFAULT,
    } as LocationSelectionDataType)

    const [
        optionChart,
        setOptionChart,
    ] = useState<ForecastAvailabilityOptions>({
        containerStatus: {
            [ContainerAvailabilityForecastContainerStatuses.ToBeProcessed]: true,
            [ContainerAvailabilityForecastContainerStatuses.InProcess]: false,
            [ContainerAvailabilityForecastContainerStatuses.Processed]: false,
            [ContainerAvailabilityForecastContainerStatuses.InPreconditioning]: false,
            [ContainerAvailabilityForecastContainerStatuses.Preconditioned]: true,
        },
        productType: null,
    })

    const {
        data: serviceCenter,
    } = useGetServiceCenterByLocation(locationSelectionData.originServiceCenter?.id)

    const {
        data: containerAvailability,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerAvailability({
        serviceCenter: {
            id: serviceCenter?.id,
        },
        containersRequestedHandoverDate: order?.requestedHandover,
        requiredContainers: order?.requiredContainers,
        excludeOrderNumber: order?.orderNumber,
    })

    useEffect(() => {
        if (!optionChart.productType && containerAvailability?.forecasts) {
            setOptionChart({
                ...optionChart,
                productType: containerAvailability?.forecasts?.[0]?.productType,
            })
        }
    }, [
        containerAvailability?.forecasts,
        optionChart,
    ])

    const [
        openPostpone,
        setPostponeOpen,
    ] = useState<boolean>(false)

    const {
        mutate: updateOriginServiceCenter,
        isPending: isLoadingSaveLocationSelection,
    } = useUpdateOriginServiceCenter(onSuccess)

    const saveLocationSelection = () => {
        updateOriginServiceCenter({
            data: locationSelectionData,
            version: positioningSetup?.version,
            orderNumber: order?.orderNumber,
        })
    }

    // check if data was changed, if default selection is other,
    // location or contacts can be changed without changing selection

    const isSaveLocationEnabled = locationSelectionData.originServiceCenterSelection
        !== positioningSetup.originServiceCenterSelection
        || (positioningSetup.originServiceCenterSelection === PositioningSelection.OTHER
            && (locationSelectionData.originServiceCenter !== positioningSetup.originScOther
            || locationSelectionData.originServiceCenterContacts
            !== positioningSetup.originScOtherContacts))

    const onSuccessOrderChangeStatus = () => {
        CustomerOrdersRoutes.Overview.navigate({
            params: {
                key: order?.orderNumber,
            },
        })
    }

    const {
        mutate,
    } = useUpdateStatusOrder(onSuccessOrderChangeStatus, order?.orderNumber)

    const confirmOrder = () => {
        mutate()
    }

    const allowedValuesProductType = useMemo(() => {
        return containerAvailability?.forecasts?.reduce((acc, {
            productType,
        }) => {
            return {
                ...acc,
                [productType]: productType,
            }
        }, {})
    }, [containerAvailability?.forecasts])

    const dataChart = useMemo(() => {
        return containerAvailability?.forecasts?.find(({
            productType,
        }) => {
            return productType === optionChart.productType
        })
    }, [
        containerAvailability?.forecasts,
        optionChart.productType,
    ])

    return (
        <>
            <PositioningSetup
                data={positioningSetup}
                order={order}
                isDeliveryRoad={isDeliveryRoad}
                onSuccess={onSuccess}
            />
            <LocationSelection
                positioningSetup={positioningSetup}
                locationSelectionData={locationSelectionData}
                setLocationSelectionData={setLocationSelectionData}
                isDeliveryRoad={isDeliveryRoad}
                serviceCenter={serviceCenter}
            />
            <Card
                className={classes.requiredContainers}
                title={`Container Availability As Of ${strOrDateToDateStr(order?.requestedHandover)}`}
            >
                <RequiredContainersAvailability
                    value={containerAvailability?.requiredContainersAvailability}
                />
            </Card>
            {containerAvailability?.forecasts && (
                <Card
                    contentClass={classes.cardContent}
                    data-testid="availability-forecast-card"
                    title="Container Availability Forecast"
                >
                    <StatusHandler
                        isSuccess={isDataReady && Boolean(dataChart)}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                    >
                        <>
                            <OptionPanel
                                value={optionChart}
                                onChange={setOptionChart}
                                allowedValues={allowedValuesProductType}
                            />
                            <ContainerAvailabilityForecastChart
                                value={dataChart?.containerAvailabilityForecastForDates || []}
                                listStatuses={Object.keys(optionChart.containerStatus)}
                                containerStatus={optionChart.containerStatus}
                                minPreconditioned={dataChart?.minPreconditioned}
                            />
                        </>
                    </StatusHandler>
                </Card>
            )}
            <ButtonContainer>
                {isDeliveryRoad && (
                    <Button
                        label="Save Location Selection"
                        onClick={saveLocationSelection}
                        data-testid="save-btn-selection"
                        disabled={!isSaveLocationEnabled}
                        saving={isLoadingSaveLocationSelection}
                    />
                )}
                <Button
                    label="confirm order"
                    onClick={confirmOrder}
                    disabled={isSaveLocationEnabled}
                />
                <Button
                    secondary
                    label="cancel order"
                    onClick={() => { setCancellationOpen(true) }}
                />
                <Button
                    data-testid="postpone-order-btn"
                    secondary
                    label="postpone order"
                    onClick={() => { setPostponeOpen(true) }}
                />
            </ButtonContainer>
            <CancellationOrderDialog
                orderNumber={order?.orderNumber}
                onSuccess={onSuccessOrderChangeStatus}
                open={openCancellation}
                setOpen={setCancellationOpen}
            />
            <PostponeDialog
                open={openPostpone}
                setOpen={setPostponeOpen}
                onSuccess={invalidateOrder}
                order={order}
            />
        </>
    )
}

export default OrderConfirmation
