import React, {
    useCallback, useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    RequestConfig,
} from 'app/types/request.types'
import useFieldsWithClassNames from 'app/hooks/useFieldsWithClassName'
import useValidation from 'app/hooks/useValidation'
import useEnumValues from 'app/hooks/useEnumValues'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    AccountLane,
    AccountLaneForm as AccountLaneFormType,
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    isCollection, isDeliveryRoad, showFTL, showLTL,
} from 'app/Apps/Pricing/utils/utils'

import useGetRegionalGroupById from 'app/Apps/AccountManagement/RegionalGroups/hooks/useGetRegionalGroupById'
import useGetOpportunityById from 'app/Apps/AccountManagement/Opportunities/hooks/useGetOpportunityById'
import {
    Country,
} from 'app/Apps/ContactManagement/Countries/Countries.types'
import getFields from './AccountLaneForm.fields'
import useStyles from './AccountLaneForm.styles'
import useGetLaneRequestedAwardedProductTypes from '../hooks/useGetLaneRequestedAwardedProductTypes'

export type AccountLaneFormFieldsArgs = {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL: boolean,
    showLTL: boolean,
    isDeliveryRoad: boolean,
    isCollection: boolean,
    handoverPointCountry?: Country | {id: number},
    collectionDropoffPointCountry?: Country | {id: number},
    showProductTypes?: boolean,
    showAwardedProductTypes?: boolean,
    exists: boolean,
    contractBasisAdminPermission: boolean,
    getAddressCreationLink?: () => JSX.Element[] | string[],
}

const defaultProps = {
    onCancel: undefined,
    data: {} as AccountLane,
    disabled: false,
    exists: false,
    customButtons: undefined,
    getCustomFields: undefined,
    getCustomButtons: undefined,
    getAddressCreationLink: undefined,
    customClasses: {},
}

const AccountLaneForm = ({
    exists,
    data,
    requestParam,
    onSuccess,
    onCancel,
    disabled,
    customButtons: predefinedCustomButtons,
    getCustomFields,
    getCustomButtons,
    getAddressCreationLink,
    customClasses,
}: {
    data?: AccountLane,
    requestParam: RequestConfig,
    onSuccess: (...args: any[]) => void,
    onCancel?: (...args: any[]) => void,
    disabled?: boolean,
    exists?: boolean
    customButtons?: JSX.Element[],
    getCustomFields?: (
        args: Partial<AccountLaneFormFieldsArgs>
    ) => DTOCardFormField<AccountLaneFormType>[],
    getCustomButtons?: ({
        isValid, synced,
    }: {
        isValid: boolean,
        synced?: boolean,
    }) => JSX.Element[],
    getAddressCreationLink?: () => JSX.Element[],
    customClasses?: Record<string, string>,
}) => {
    const showProductTypes = data?.id && (data?.laneStatus !== LaneStatus.TO_BE_QUOTED)

    const {
        data: productTypes,
    } = useGetLaneRequestedAwardedProductTypes(showProductTypes ? data?.id : undefined)

    const {
        classes,
    } = useStyles({
        showProductTypes,
    })

    const getAllowedValues = useEnumValues()

    const [
        value,
        setValue,
    ] = useState<AccountLane>(data)
    const contractBasisAdminPermission = useHasPermission(['contract_basis_admin'])

    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel()
            return
        }

        setValue(data)
    }, [
        onCancel,
        data,
    ])

    const {
        data: opportunity,
    } = useGetOpportunityById(value?.opportunity?.id)

    const {
        data: regionalGroup,
    } = useGetRegionalGroupById(opportunity?.regionalGroup?.id)

    const getFieldsFunc = useMemo(() => {
        if (getCustomFields) {
            return getCustomFields
        }
        return getFields
    }, [getCustomFields])

    const fields = useFieldsWithClassNames(
        getFieldsFunc({
            getAllowedValues,
            isDeliveryRoad: isDeliveryRoad(value?.deliveryServiceType),
            showFTL: showFTL(value?.transportModeRoad),
            showLTL: showLTL(value?.transportModeRoad),
            isCollection: isCollection(value?.collectionServiceType),
            contractBasisAdminPermission,
            collectionDropoffPointCountry: value?.collectionDropoffPointCountry,
            handoverPointCountry: value?.handoverPointCountry,
            getAddressCreationLink,
            showProductTypes,
            showAwardedProductTypes: [
                LaneStatus.AWARDED,
                LaneStatus.IMPLEMENTED,
            ].includes(data?.laneStatus),
            exists,
        }), classes,
    )

    const formValues = useMemo(() => {
        return {
            ...value,
            account: regionalGroup?.account,
            temperatureRange: regionalGroup?.tempRange,
            originAirport: value?.originAirport || opportunity?.reprOriginAirport,
            destinationAirport: value?.destinationAirport
                || opportunity?.reprDestinationAirport,
            isLtlPositioningTemperatureControlled:
                (isDeliveryRoad(value.deliveryServiceType)
                    && value?.isLtlPositioningTemperatureControlled === undefined)
                    ? true : value?.isLtlPositioningTemperatureControlled,
            isFtlPositioningTemperatureControlled:
                (isDeliveryRoad(value.deliveryServiceType)
                    && value?.isFtlPositioningTemperatureControlled === undefined)
                    ? true : value?.isFtlPositioningTemperatureControlled,
            requestedProductTypes: productTypes?.requestedProductTypes,
            awardedProductTypes: productTypes?.awardedProductTypes,
        }
    }, [
        value,
        opportunity?.reprOriginAirport,
        opportunity?.reprDestinationAirport,
        regionalGroup?.tempRange,
        regionalGroup?.account,
        productTypes,
    ])

    const modifyDataBeforeSend = useCallback((accountLane) => {
        let payload = accountLane

        if (!isDeliveryRoad(accountLane.deliveryServiceType)) {
            payload = {
                ...payload,
                transportModeRoad: undefined,
                isFtlPositioningTemperatureControlled: undefined,
                isLtlPositioningTemperatureControlled: undefined,
                handoverPointZipCode: undefined,
                handoverPointAddress: undefined,
                handoverPointCountry: undefined,
            }
        }

        if (!showLTL(accountLane.transportModeRoad)) {
            payload = {
                ...payload,
                isLtlPositioningTemperatureControlled: undefined,
            }
        }

        if (!showFTL(accountLane.transportModeRoad)) {
            payload = {
                ...payload,
                isFtlPositioningTemperatureControlled: undefined,
            }
        }

        if (!isCollection(accountLane.collectionServiceType)) {
            payload = {
                ...payload,
                collectionDropoffPointZipCode: undefined,
                collectionDropoffPointAddress: undefined,
                collectionDropoffPointCountry: undefined,
            }
        }

        return payload
    }, [])

    const {
        isValid,
    } = useValidation({
        fields,
        values: formValues,
    })

    const customButtons = useMemo(() => {
        if (getCustomButtons) {
            return getCustomButtons({
                isValid, synced: isEqual(data, value),
            })
        }
        return predefinedCustomButtons
    }, [
        data,
        value,
        isValid,
        predefinedCustomButtons,
        getCustomButtons,
    ])

    return (
        <FormWithControls
            disabled={disabled}
            value={formValues}
            name="AccountLane"
            requestParams={requestParam}
            setExternalValue={setValue}
            fields={fields}
            onSuccess={onSuccess}
            onCancel={handleCancel}
            modifyDataBeforeSend={modifyDataBeforeSend}
            classNames={{
                gridWrapper: classes.gridWrapper,
                ...customClasses,
            }}
            exists={exists}
            customButtons={customButtons}
        />
    )
}

AccountLaneForm.defaultProps = defaultProps

export default AccountLaneForm
